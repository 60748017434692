import { Col, Container, Table, Button, Modal } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom'
import useApi from '../utilities/useApi';
import { useEffect, useState } from 'react';
import moment from 'moment';
import roleUtilities from '../utilities/roleUtilities';

export default function ApiKeys(props) {
    const { api } = useApi();
    const navigate = useNavigate();
    const { organisationId } = useParams();
    const [organisation, setOrganisation] = useState(null);
    const [allApiKeys, setAllApiKeys] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [revokeId, setRevokeId] = useState(null);

    const fetchAllApiKeys = () => {
        api.getApiKeys(
            organisationId,
            data => setAllApiKeys(data),
            error => console.log(error)
        )
    }

    const fetchOrganisation = () => {
        api.getOrganisationalUnit(
            organisationId,
            data => setOrganisation(data),
            error => console.log(error)
        )
    }

    useEffect(() => {
        fetchOrganisation()
        fetchAllApiKeys()
    }, [])

    const handleRevoke = (apiKeyId) => {
        api.revokeApiKey(
            organisationId, apiKeyId,
            () => {
                setShowModal(false);
                fetchAllApiKeys()
            },
            error => console.log(error)
        )
    }

    const fetchStatusMessage = (apiKey) => {
        if (apiKey.revokedAt) {
            return <p className='m-0 text-danger'>Revoked</p>
        }
        if (apiKey.expiresAt && moment(new Date(apiKey.expiresAt)).isBefore(moment())) {
            return <p className='m-0 text-warning'>Expired</p>
        }
        if (!apiKey.isActive) {
            return <p className='m-0 text-inactive'>Inactive</p>
        }
        return <p className='m-0 text-success'>Active</p>
    }

    return (
        <Container className='pt-5 pb-lg-4 mt-5 mb-sm-2'>
            <Col className='mb-5'>
                {
                    organisation &&
                    <div className='api-keys-landing-page'>
                        <div className='d-flex justify-content-between my-5'>
                            <h1 className="h2 m-0">{ organisation.name } - API Keys</h1>
                            <div className='d-flex align-items-center'>
                                <Button style={ { marginRight: "10px" } } size='sm' variant='outline-primary' onClick={ () => navigate('/organisations') }>
                                    <span className='d-flex align-items-center'><i className='fi-arrow-left me-2'></i>Back to Organisations</span>
                                </Button>
                                {
                                    roleUtilities.isGlobalAdminOrOrgAdmin(props.user.roleId) &&
                                    <Button size='sm' onClick={ () => navigate(`/organisations/${ organisationId }/api-keys/add`) }>
                                        <span className='d-flex align-items-center'><i className='fi-plus me-2'></i>Add API Key</span>
                                    </Button>
                                }
                            </div>
                        </div>

                        <Table responsive hover className='projects-table'>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Key</th>
                                    <th>Status</th>
                                    <th>Expiry Date</th>
                                    <th>&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    allApiKeys && allApiKeys.length > 0 &&
                                    allApiKeys.map(apiKey => <tr key={ apiKey.id }>
                                        <td className='align-middle'>{ apiKey.name }</td>
                                        <td className='align-middle'>{ apiKey.key }</td>
                                        <td className='align-middle'>{ fetchStatusMessage(apiKey) }</td>
                                        <td className='align-middle'>{ apiKey.expiresAt ? moment(new Date(apiKey.expiresAt)).format("DD-MM-YYYY") : null }</td>
                                        <td className='align-middle'>
                                            <div className='d-flex w-100 justify-content-end'>
                                                <Button variant='secondary' size="sm" className='me-2 btn btn-light-primary shadow-sm btn-sm' onClick={ () => navigate(`/organisations/${ organisationId }/api-keys/${ apiKey.id }/edit`) }>
                                                    <span className='d-flex align-items-center'><i className='fi-edit me-2'></i>Edit</span>
                                                </Button>
                                                <Button disabled={ apiKey.revokedAt } variant='none' size="sm" className='me-2 btn btn-outline-danger shadow-sm btn-sm' onClick={ () => {
                                                    setRevokeId(apiKey.id);
                                                    setShowModal(true);
                                                } }>
                                                    <span className='d-flex align-items-center'><i className='fi-arrow-back-up me-2'></i>Revoke</span>
                                                </Button>
                                            </div>
                                        </td>
                                    </tr>)
                                }
                            </tbody>
                        </Table>
                    </div>
                }
            </Col>
            {
                revokeId &&
                <Modal show={ showModal } onHide={ () => {
                    setRevokeId(null)
                    setShowModal(false);
                } } centered>
                    <Modal.Header>
                        <Modal.Title>Warning</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Are you sure you want to revoke this API Key?
                    </Modal.Body>
                    <Modal.Footer>
                        <Button size="sm" variant='secondary' onClick={ () => {
                            setRevokeId(null)
                            setShowModal(false);
                        } }>Cancel</Button>
                        <Button size="sm" variant="primary" onClick={ () => handleRevoke(revokeId) }>Confirm</Button>
                    </Modal.Footer>
                </Modal>
            }
        </Container>
    )
}